import React, { useState, useCallback } from 'react'

import AbstractModal from '../components/AbstractModal'
import AddButton from '../components/common/AddButton'
import CreateContent from '../components/forms/content/CreateContent'
import SubCategoryFilter from '../components/filters/SubCategoryFilter'
import ContentList from '../components/modern-lists/content/ContentList'
import SubjectFilter from '../components/filters/SubjectFilter'
import CategoryFilter from '../components/filters/CategoryFilter'

const Contents: React.FC = () => {
  const [show, setShow] = useState(false)
  const [subCatId, setSubCatId] = useState<number>()
  const [categoryId, setCategory] = useState<number>()
  const [subjectId, setSubjectId] = useState<number>()
  const [search, setSearch] = useState<string>('')

  const handleSubCatChange = useCallback((v: number) => {
    setSubCatId(v)
  }, [])

  const handleSearch = useCallback((v: string) => {
    setSearch(v)
  }, [])

  const handleCategorySelect = useCallback((v:number) => {
    setCategory(v)
  },[])

  const handleSubjectSelect = useCallback((v:number) => {
    setSubjectId(v)
  },[])

  const handleShow = useCallback((v: boolean) => {
    setShow((prev: boolean) => !prev)
  }, [])

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <AddButton
        setSearch={handleSearch}
        show={show}
        setShow={handleShow}
        filter={<SubjectFilter value={subjectId} setOptionValue={handleSubjectSelect} />}
        filter2={<CategoryFilter setOptionValue={handleCategorySelect} value={categoryId} subjectId={subjectId}  />} 
        filter3={<SubCategoryFilter value={subCatId} categoryId={categoryId} setOptionValue={handleSubCatChange} />}
      />
      <ContentList search={search} subCatId={subCatId} categoryId={categoryId} />
      <AbstractModal
        Component={<CreateContent handleClose={() => setShow(false)} />}
        handleClose={() => setShow(false)}
        show={show}
      />
    </div>
  )
}

export default Contents