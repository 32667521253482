import React, { useEffect } from 'react'
import ContentItem from './ContentItem';
import { useQuery } from '@apollo/client';
import { notification } from '../../../service';
import GetContents from '../../../graphql/queries/GetContents';

interface Props {
    search: string;
    subCatId?: number;
    categoryId?: number;
};

const ContentList: React.FC<Props> = ({ search, subCatId, categoryId }) => {
    const { error, data, loading } = useQuery(GetContents, {
        fetchPolicy: "network-only",
        variables: {
          where: {
            search,
            subCategoryId: subCatId,
            categoryId
          }
        }
      });

    useEffect(() => {
        if (!loading && error && error.message) {
            notification({ message: error.message, type: "danger" });
        }
    }, [loading, error]);

    return (
        <div style={{display: 'flex', flexWrap: 'wrap', margin: '0px 20px', height: 'calc(100vh - 166px)', overflowY: 'auto'}}>
            {
                data?.contents?.map((item: any, key: number) => (
                    <ContentItem item={item} key={key} />
                ))
            }
        </div>
    )
}

export default ContentList